.App {
  text-align: center;
}

.App-link {
  color: #61dafb;
}

:root {
  --bus-marker-size: 50px;
}

.mapboxgl-popup.mapboxgl-popup-anchor-top {
  z-index: 5;
}

/* Override style for bus route overlay canvas */
canvas {
  z-index: -1;
}

/*.mapboxgl-popup {*/
  /*max-width: 200px;*/
/*}*/

/*.mapboxgl-popup-content {*/
  /*text-align: center;*/
  /*font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',*/
    /*monospace;*/
/*}*/
